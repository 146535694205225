@import "styles/variables.scss";

.video_wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 32px;
  background-repeat: no-repeat;
  background-size: cover;

  @include mobile {
    border-radius: 12px;
  }
  & video {
    border-radius: 32px;
    height: 748px;
    width: 100%;
    @include laptop {
      height: auto;
    }
    @include mobile {
      border-radius: 12px;
    }
  }
  // & .backdrop {
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   right: 0;
  //   left: 0;
  //   background-color: rgba(24, 24, 24, 0.3);
  //   transition: opacity 0.3s;
  //   pointer-events: none;
  // }
}

.wrapper {
  width: 100%;
  margin-bottom: 105px;
  @include laptop {
    margin-bottom: 75px;
  }
  @include mobile {
    margin-bottom: 45px;
  }
}

input[type="range"] {
  overflow: hidden;
  -webkit-appearance: none;
  background-color: rgba(24, 88, 251, 0.1);
  width: 100%;
  height: 8px;
  border-radius: 15px;
  @include mobile {
    height: 4px;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
    border-radius: 100px !important;
    background: $text-blue;
    box-shadow: -800px 0 0 800px $text-blue;
  }
  &::-webkit-slider-runnable-track {
    -webkit-appearance: none !important;
    height: 8px;
  }
  &::-moz-range-progress {
    background-color: $text-blue;
  }
  &::-ms-fill-lower {
    background-color: $text-blue;
  }
  &::-moz-range-track {
    background-color: rgba(24, 88, 251, 0.1);
  }
  &::-ms-fill-lower {
    background-color: $text-blue;
  }
  &::-ms-fill-upper {
    background-color: rgba(24, 88, 251, 0.1);
  }
}

.button {
  &_wrapper {
    position: relative;
  }
  &_mute,
  &_fullscreen,
  &_play {
    border-radius: 100%;
    z-index: 100;
    transition: opacity 0.3s;
    position: absolute;
    & svg {
      height: 100%;
      width: 100%;
    }

    & circle {
      transition: fill-opacity 0.3s;
    }
    &:hover {
      & circle {
        fill-opacity: 0.3;
      }
    }
  }
  &_play {
    width: 158px;
    height: 158px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(5px);
    @include laptop {
      width: 78px;
      height: 78px;
    }
    @include mobile {
      width: 42px;
      height: 42px;
    }
  }
  &_mute {
    width: 60px;
    height: 60px;
    top: 20px;
    right: 20px;
    backdrop-filter: blur(5px);
    @include laptop {
      width: 30px;
      height: 30px;
      top: 10px;
      right: 10px;
    }
  }
  &_fullscreen {
    display: none;
    background-image: url(/assets/svg/fullscreen.svg);
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 10px;
    right: 10px;
    backdrop-filter: blur(5px);

    @include mobile {
      display: block;
    }
  }
}
