@import "styles/variables.scss";

.header {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  @include mobile {
    margin-bottom: 32px;
  }
  & .button_close {
    align-items: center;
    display: flex;
    & line {
      transition: stroke 0.3s;
    }
    &:hover {
      & line {
        stroke: $projects_text;
      }
    }
    @include mobile {
      width: 20px;
      height: 20px;
    }
  }
  & > p {
    max-width: 70%;
    align-self: center;
    @include ibm;
    font-size: 40px;
    line-height: 46px;
    color: $projects-text;
    text-align: center;
    text-transform: uppercase;
    @include mobile {
      font-size: 22px;
      line-height: 26px;
      max-width: 100%;
    }
  }
}

.content {
  margin: 0 auto 63px;
  padding: 0 50px !important;
  @include mobile {
    padding: 0 !important;
    margin: 0 15px 40px;
  }
  & > ul {
    position: relative;
    display: flex;
    gap: 40px;
    margin-bottom: 35px;
    @include mobile {
      margin-bottom: 24px;
      flex-direction: column;
      gap: 0;
    }
    & > li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 62px;
        flex-shrink: 0;
        height: 62px;
        border: 1px solid $projects_text;
        border-radius: 100px;
        font-size: 40px;
        line-height: 44px;
        @include ibm;
        margin-right: 12px;
      }
      & > p {
        font-size: 16px;
        line-height: 20px;
        @include mulish;
      }
    }
  }
  & .images {
    text-align: center;
    height: 225px;
    position: relative;
    @include mobile {
      height: auto;
      margin-bottom: 32px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    & > svg {
      position: absolute;
      @include mobile {
        display: none;
      }
      &:first-child {
        top: -20px;
        left: -30px;
      }
      &:nth-child(2) {
        top: -50px;
        right: -30px;
      }
    }
    &_ellipse {
      @include mobile {
        width: 230px;
        height: 134px;
      }
      &:last-child {
        margin-left: -50px;
        @include mobile {
          align-self: flex-start;
          margin: -20px 0 0;
        }
      }
    }
  }
  & > button {
    display: block;
    margin: 0 auto;
    font-size: 20px;
    line-height: 52px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    padding: 0 188px;
    background-color: $text-blue;
    @include ibm500;
    border-radius: 100px;
    border: 4px solid $text-blue;
    transition: color 0.3s, background-color 0.3s;
    @include mobile {
      padding: 16px 0;
      width: 100%;
      font-size: 16px;
      line-height: 21px;
    }
    &:hover {
      background-color: transparent;
      color: $text-blue;
    }
  }

  & iframe {
    overflow: hidden !important;
    height: 900px;
    width: 700px;
    max-width: 100%;
    @include mobile {
      height: 70vh;
      width: 90vw;
      max-width: 90vw;
    }
  }
}
