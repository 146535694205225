@import "styles/variables.scss";

.interior_1,
.interior_2,
.interior_3 {
  width: 100%;
  height: 943px;
  &:hover {
    & .swiper-button-prev,
    & .swiper-button-next {
      &::after {
        opacity: 0.8;
        transition: opacity 0.3s;
      }
    }
  }
  & .swiper-slide {
    height: 900px;
  }
  & .swiper-pagination-bullet {
    height: 17px;
    width: 17px;
    background-color: transparent;
    border: 1px solid $projects_text;
    opacity: 1;
    &-active {
      background-color: $text-blue;
      border: none;
    }
  }
  & .swiper-button-prev,
  & .swiper-button-next {
    width: 48px;
    height: 48px;
    &::after {
      transition: opacity 0.3s;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: url(../assets/nft/interior/arrow.png);
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.3;
      border-radius: 100px;
    }
  }
  & .swiper-button-next {
    &::after {
      transform: scale(-1, 1);
    }
  }
}

@media (max-width: 1400px) {
  .interior_1,
  .interior_2,
  .interior_3 {
    height: 560px;
    & .swiper-slide {
      height: 523px;
    }
    & .swiper-pagination-bullet {
      height: 13px;
      width: 13px;
    }
    & .swiper-button-prev,
    & .swiper-button-next {
      top: calc(50% - 15px);
    }
  }
}

@media (max-width: 770px) {
  .interior_1,
  .interior_2,
  .interior_3 {
    height: 270px;
    & .swiper-slide {
      height: 236px;
    }
    & .swiper-pagination-bullet {
      height: 10px;
      width: 10px;
      cursor: pointer !important;
    }
    & .swiper-button-prev,
    & .swiper-button-next {
      transform: scale(0.7);
    }
  }
}

.swiper-services {
  & .swiper-slide {
    width: 90%;
    transform: scale(0.8);
    transition: transform 0.3s;
  }
  
  & .swiper-slide-active {
    transform: none;
  }
}
