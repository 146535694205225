@import "styles/variables.scss";

.projects_page {
  margin-top: 50px;
  margin-bottom: 20px;
}

.title {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 68px;
  line-height: 79px;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 32px;
  // margin-bottom: 60px;
}

//--------MEDIA--------

@media (max-width: 1400px) {
  .projects_page {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .title {
    font-size: 50px;
    line-height: 40px;
    margin-bottom: 20px;
  }
}

@media (max-width: 770px) {
  .projects_page {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .title {
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 20px;
  }
}
