@import "styles/variables.scss";

.wr {
  display: grid;
  grid-template-columns: repeat(2, 344px) repeat(2, 1fr);
  grid-template-rows: 344px 236px;
  gap: 31px;
  @include laptop {
    gap: 11px;
    grid-template-columns: repeat(2, 1.5fr) repeat(2, 1fr);
    grid-template-rows: 220px 136px;
  }
  @include mobile {
    display: block;
  }
  & .card {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 16px;

    @include mobile {
      height: 100% !important;
      aspect-ratio: 1/1;
      width: 100%;
      z-index: 1 !important;
      &:hover {
        & p {
          opacity: 1;
          color: #fff;
        }
        z-index: 1 !important;
        & span,
        & button,
        & .img_wr,
        & p,
        & > section {
          transform: none !important;
        }
      }
    }

    & > section {
      height: 100%;
      background-color: #fff;
      color: #000;
      transition: transform 0.3s, background-color 0.3s, border-color 0.3s,
        color 0.3s;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid $projects_text;
      border-radius: 16px;
      padding: 10px 10px 12px 12px;
      @include laptop {
        padding: 6px;
      }
      @include mobile {
        justify-content: flex-start;
        background-color: $text-blue;
        border-radius: 16px;
        color: #fff;
        padding: 15px;
        border: none;
      }

      & > button {
        align-self: flex-end;
        border-radius: 100%;
        width: 90px;
        height: 90px;
        background-color: $projects_text;
        display: flex;
        align-items: center;
        justify-content: center;
        @include laptop {
          width: 52.2px;
          height: 52.2px;
          & svg {
            width: 30%;
          }
        }
        @include mobile {
          position: absolute;
        }
      }
      & > span {
        transform-origin: bottom left;
        transition: transform 0.3s;
        display: block;
        font-size: 32px;
        line-height: 40px;
        @include ibm;
        text-transform: uppercase;
        @include laptop {
          font-size: 18.6px;
          line-height: 22px;
        }
        @include mobile {
          font-size: 26px;
          line-height: 30px;
          margin-bottom: 14px;
          max-width: 90%;
        }
      }
      & > p {
        position: absolute;
        opacity: 0;
        @include mulish;
        font-size: 16px;
        @include laptop {
          font-size: 10px;
        }
        @include mobile {
          opacity: 1;
          transform: none;
          position: static;
          font-size: 16px;
        }
      }
    }

    & .img_wr {
      pointer-events: none;
      position: absolute;
      border-radius: 14px;
      aspect-ratio: 1/1;
      overflow: hidden;
      width: 246px;
      height: 246px;
      transition: transform 0.3s;
      z-index: -2;
      right: 0;
      top: 0;
      transition: transform 1.5s cubic-bezier(0.25, 1, 0.5, 1);

      @include laptop {
        width: 143px;
        height: 143px;
      }
      @include mobile {
        width: 126px;
        height: 126px;
        z-index: 10;
        right: initial;
        top: initial;
      }
    }
    transition: box-shadow 0.3s;
    &:hover {
      z-index: 11;
      box-shadow: 23px 32px 43px 0px rgba(24, 88, 251, 0.64);

      & > section {
        background-color: $text-blue;
        border-color: $text-blue;
        color: #fff;
        transform: scale(1.02);

        & > p {
          opacity: 1;
          bottom: 12px;
          left: 12px;
          @include laptop {
            bottom: 6px;
            left: 6px;
          }
        }
      }
    }
  }
}

// ---- cards ----

.ar {
  grid-column: 3;
  grid-row: 1;

  &:hover {
    & span {
      transform: translateY(-132px);
      @include laptop {
        transform: translateY(-90px);
      }
    }
    & .img_wr {
      &:first-of-type {
        transform: translate(-30%, 100%) rotate(-15deg);
      }
      &:nth-of-type(2) {
        transform: translate(90%, 70%) rotate(-7deg);
      }
      &:nth-of-type(3) {
        transform: translate(-100%, -15%) rotate(15deg);
      }
    }
  }
  @include mobile {
    & .img_wr {
      &:first-of-type {
        bottom: -10px;
        left: -5px;
        transform: rotate(-5deg);
      }
      &:nth-of-type(2) {
        bottom: -15px;
        z-index: 11 !important;
        transform: rotate(5deg);
        right: -10px;
      }
      &:nth-of-type(3) {
        bottom: 10%;
        left: 30%;
      }
    }
  }
}

.web {
  grid-column: 2;
  grid-row-start: 1;
  grid-row-end: 3;

  &:hover {
    & span {
      transform: translateY(-92px);
      @include laptop {
        transform: translateY(-55px);
      }
    }

    & .img_wr {
      &:first-of-type {
        transform: translate(-110%, -10%) rotate(-19deg);
      }
      &:nth-of-type(2) {
        transform: translate(90%, 180%) rotate(15deg);
      }
      &:nth-of-type(3) {
        transform: translate(-120%, 150%) rotate(-10deg);
      }
      &:nth-of-type(4) {
        transform: translate(90%, 40%) rotate(-10deg);
      }
    }
  }

  @include mobile {
    & .img_wr {
      &:first-of-type {
        display: none;
      }
      &:nth-of-type(2) {
        bottom: -15px;
        transform: rotate(5deg);
        right: -10px;
      }
      &:nth-of-type(3) {
        bottom: -10px;
        left: 0;
        transform: rotate(-5deg);
      }
      &:nth-of-type(4) {
        bottom: 10%;
        left: 30%;
        z-index: 9;
      }
    }
  }
}

.app {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column: 1;

  &:hover {
    & span {
      transform: translateY(-152px);
      @include laptop {
        transform: translateY(-90px);
      }
    }

    & .img_wr {
      &:first-of-type {
        transform: translate(100%, -10%) rotate(-15deg);
      }
      &:nth-of-type(2) {
        transform: translate(110%, 100%) rotate(15deg);
        // z-index: -10;
      }
      &:nth-of-type(3) {
        transform: translate(-10%, 220%) rotate(10deg);
      }
      &:nth-of-type(4) {
        transform: translate(90%, 190%) rotate(10deg);
      }
    }
  }

  @include mobile {
    & .img_wr {
      &:first-of-type {
        left: -10px;
        bottom: -15px;
        transform: rotate(-5deg);
      }
      &:nth-of-type(2) {
        bottom: -10%;
        left: 30%;
        z-index: 99;
      }
      &:nth-of-type(3) {
        display: none;
      }
      &:nth-of-type(4) {
        bottom: -15px;
        transform: rotate(5deg);
        right: -10px;
      }
    }
  }
}

.game {
  grid-column: 4;
  grid-row: 1;

  &:hover {
    & span {
      transform: translateY(-110px);
      @include laptop {
        transform: translateY(-70px);
      }
    }
    & .img_wr {
      &:first-of-type {
        transform: translate(-75%, -10%) rotate(-15deg);
      }
      &:nth-of-type(2) {
        z-index: -1;
        transform: translate(-100%, 90%) rotate(7deg);
      }
      &:nth-of-type(3) {
        transform: translate(-10%, 130%) rotate(-15deg);
      }
    }
  }
  & > section > p {
    max-width: 90%;
  }

  @include mobile {
    & .img_wr {
      &:first-of-type {
        bottom: -10px;
        left: -5px;
        transform: rotate(-5deg);
      }
      &:nth-of-type(3) {
        bottom: -15px;
        transform: rotate(5deg);
        right: -10px;
        z-index: 11;
      }
      &:nth-of-type(2) {
        bottom: -10%;
        left: 30%;
        z-index: 10 !important;
      }
    }
    & span {
      max-width: 80% !important;
    }
  }
}

.graph {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row: 2;
  & > section > p {
    max-width: 80%;
  }
  & .img_wr {
    width: 235px !important;
    height: 235px !important;
    @include laptop {
      width: 130px !important;
      height: 130px !important;
    }
  }
  &:hover {
    & span {
      transform: translateY(-52px);
      @include laptop {
        transform: translateY(-32px);
      }
    }
    & .img_wr {
      &:first-of-type {
        transform: translate(-245%, 00%) rotate(-13deg);
      }
      &:nth-of-type(2) {
        transform: translate(-180%, -80%) rotate(-15deg);
      }
      &:nth-of-type(3) {
        transform: translate(-80%, -80%) rotate(10deg);
      }
    }
  }

  @include mobile {
    & .img_wr {
      &:first-of-type {
        bottom: -10px;
        left: -5px;
        transform: rotate(-5deg);
      }
      &:nth-of-type(2) {
        bottom: -15px;
        z-index: 11 !important;
        transform: rotate(5deg);
        right: -10px;
      }
      &:nth-of-type(3) {
        bottom: 10%;
        left: 30%;
      }
    }
  }
}

// --- slider ---

.slider {
  width: 100%;
  height: 357px;
  overflow: visible !important;
  &::before {
    pointer-events: none;
    z-index: -100;
    content: "";
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(24, 88, 251, 0.5);
    filter: blur(98px);
    opacity: 1;
    transition: opacity 0.3s;
  }
}
