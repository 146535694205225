@import "styles/variables.scss";

.mariinskiy {
  .about {
    color: $projects_text;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 33px;
    @include laptop {
      margin-bottom: 20px;
    }
    @include mobile {
      flex-direction: column;
    }
    &_left {
      width: 50%;
      @include laptop {
        padding-bottom: 20px;
        margin-right: 14px;
      }
      @include mobile {
        width: 100%;
        padding-bottom: 20px;
      }
      &_title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 30px;

        @include laptop {
        }
        @include mobile {
          padding-bottom: 15px;
          margin-bottom: 20px;
        }
        &_name {
          color: #181818;
          font-family: IBM Plex Mono;
          font-size: 68px;
          font-style: normal;
          font-weight: 400;
          line-height: 80px; /* 116.176% */
          text-transform: uppercase;
          margin-bottom: 10px;
          @include laptop {
            font-size: 45px;
            line-height: 60px;
            margin-bottom: 30px;
          }
          @include mobile {
            font-size: 45px;
            line-height: 60px;
            margin-bottom: 10px;
          }
        }
        &_label {
          font-family: Mulish;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          @include laptop {
          }
          @include mobile {
            font-size: 14px;
            line-height: 20px;
          }
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 544px;
          border-bottom: 1px solid $text-dark;
          bottom: 0;
          @include laptop {
            width: 350px;
          }
          @include mobile {
            width: 100%;
          }
        }
      }
      .buttons {
        max-width: 100%;
        margin-bottom: 67px;
        display: flex;
        @include laptop {
          max-width: 100%;
          margin-bottom: 0;
          margin-top: 0px;
        }
        @include mobile {
          flex-direction: column;
          max-width: 100%;
          margin-top: 0;
        }
        .game {
          margin-right: 10px;
          font-family: "IBM Plex Mono";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0.02em;
          text-transform: uppercase;
          color: $projects_text;
          border: 1px solid $projects_text;
          background-color: transparent;
          border-radius: 100px;
          display: inline-block;
          padding: 13px 20px;
          @include laptop {
            margin-bottom: 10px;
          }
          @include mobile {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
    &_content {
      width: 50%;
      line-height: 31px;
      @include laptop {
        width: 50%;
        line-height: 31px;
      }
      @include mobile {
        width: 100%;
      }
      &_name {
        font-style: normal;
        font-weight: 400;
        font-family: "IBM Plex Mono";
        font-size: 28px;
        margin-bottom: 27px;

        @include mobile {
          font-size: 23px;
          margin-bottom: 20px;
        }
      }
      &_label {
        font-style: normal;
        font-weight: 400;
        font-family: "Mulish";
        font-size: 24px;
        @include laptop {
          font-size: 20px;
        }

        @include mobile {
          font-size: 20px;
        }
        &_2 {
          margin: 92px 0;
          color: #181818;
          @include mulish;
          font-size: 24px;
          line-height: 31px;
          max-width: 75%;
          @include mobile {
            font-size: 20px;
            max-width: 100%;
            margin: 0 0 30px;
          }
        }
      }
    }
  }

  .task {
    width: 100%;
    margin-bottom: 64px;
    margin-top: 80px;
    color: $projects_text;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 40px;
    @include mobile {
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      padding-right: 0;
    }
    &_wrapper {
      max-width: 100%;
      @include laptop {
        max-width: 80%;
      }
      @include mobile {
        max-width: 100%;
      }
      .task_title,
      .point_title {
        font-family: "IBM Plex Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 31px;
        margin-bottom: 27px;
        @include laptop {
          font-size: 24px;
          margin-bottom: 20px;
        }
        @include mobile {
        }
      }
      .task_text {
        max-width: 55%;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 72px;
        @include laptop {
          max-width: 70%;
          font-size: 20px;
        }
        @include mobile {
          max-width: 100%;
        }
      }

      .point_text {
        max-width: 80%;
        @include laptop {
          max-width: 100%;
        }
        @include mobile {
          margin-bottom: 40px;
        }
        & li {
          margin-bottom: 20px;
          font-family: Mulish;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 31px;
          position: relative;
          padding-left: 32px;
          @include laptop {
            font-size: 20px;
          }
          @include mobile {
            margin-bottom: 10px;
            word-wrap: break-word;
            font-size: 22px;
          }
          &::before {
            content: "";
            position: absolute;
            display: block;
            border-radius: 100px;
            background-color: $secondary-blue;
            width: 20px;
            height: 20px;
            top: 5px;
            left: 0;
            @include mobile {
              width: 15px;
              height: 15px;
              top: 10px;
            }
          }
          &:nth-child(5) {
            &::before {
              display: none;
            }
          }
        }
      }
    }
    &_date {
      position: relative;
      @include mobile {
        margin-right: 35px;
        align-self: flex-end;
      }

      .number {
        display: block;
        padding: 16px 35px;
        background: rgba(119, 182, 255, 0.3);
        border-radius: 100px;
        font-family: "IBM Plex Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 68px;
        line-height: 79px;
        text-transform: uppercase;
        color: $text-blue;
        width: min-content;
      }
      .month,
      .label {
        position: absolute;
        font-family: "IBM Plex Mono";
        font-style: normal;
        font-weight: 400;
      }
      .month {
        right: -40px;
        bottom: 40%;
        transform: translateY(50%);
        font-size: 32px;
        line-height: 42px;
      }
      .label {
        bottom: -10px;
        right: -20px;
        font-size: 20px;
        line-height: 26px;
        white-space: nowrap;
        @include laptop {
          bottom: -25px;
          right: 0;
          width: 200px;
          white-space: normal;
          text-align: right;
          line-height: 20px;
        }
        @include mobile {
          width: auto;
          bottom: -10px;
          right: -20px;
          font-size: 20px;
          line-height: 26px;
          white-space: nowrap;
        }
      }
    }
  }
  .image_1,
  .image_2 {
    text-align: center;
    margin-bottom: 46px;
    & img {
      max-width: 100%;
      object-fit: contain;
    }
  }
  .image_1 {
    height: 900px;
    @include laptop {
      height: 522px;
    }
    @include mobile {
      height: 235px;
    }
  }

  .image_2 {
    height: 991px;
    @include laptop {
      height: 576px;
    }
    @include mobile {
      height: 259px;
    }
  }
  .video_container {
    margin-bottom: 0;
    @include mobile {
      margin-bottom: 60px;
    }
    @include laptop {
      margin-bottom: 60px;
    }
    video[poster] {
      object-fit: cover;
    }
  }
  .result_content {
    width: 75%;
    line-height: 31px;
    margin-top: -50px;
    margin-bottom: 53px;
    @include mobile {
      width: 100%;
    }
    &_name {
      font-style: normal;
      font-weight: 400;
      font-family: "IBM Plex Mono";
      font-size: 28px;
      margin-bottom: 27px;
      @include mobile {
        font-size: 22px;
      }
    }
    &_label {
      font-style: normal;
      font-weight: 400;
      font-family: "Mulish";
      font-size: 24px;
      @include mobile {
        font-size: 20px;
      }
    }
  }
}
