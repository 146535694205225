@import "~normalize.css";
// @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600;700&family=Mulish&family=Open+Sans:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");
@import "styles/variables.scss";

@font-face {
  font-family: "Vela Sans";
  src: local("Vela Sans"), url("/assets/fonts/VelaSans-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Vela Sans";
  src: local("Vela Sans"), url("/assets/fonts/VelaSans-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Zero Cool";
  src: local("Zero Cool"), url("/assets/fonts/ZeroCool.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "IBM Plex Mono";
  src: local("IBM Plex Mono"), url("/assets/fonts/IBMPlexMono-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "IBM Plex Mono";
  src: local("IBM Plex Mono"), url("/assets/fonts/IBMPlexMono-Text.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "IBM Plex Mono";
  src: local("IBM Plex Mono"), url("/assets/fonts/IBMPlexMono-Medium.ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "IBM Plex Mono";
  src: local("IBM Plex Mono"), url("/assets/fonts/IBMPlexMono-SemiBold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Mulish";
  src: local("Mulish"), url("/assets/fonts/Mulish-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

*,
h1,
h2,
h3,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

p,
a,
br,
span,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
img {
  &::selection {
    color: #fff;
    background-color: $projects_text;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  position: relative;
  padding: 60px 0 384px;
  min-height: 100vh;
  overflow-x: hidden;

  padding-right: 0px !important;
  overflow-y: auto !important;
}

button {
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    opacity: 0.6;
  }
}

.container {
  max-width: 1322px;
  margin: 0 auto;
}

.link,
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

li {
  list-style-position: inside;
}

ul {
  list-style-type: none;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

//--------- MEDIA ---------

@media (max-width: 1400px) {
  .container {
    max-width: 768px;
    // max-width: 95%;
  }
}

@media (max-width: 770px) {
  .link li,
  button {
    cursor: pointer;
  }
  .container {
    max-width: 346px;
    // max-width: 95%;
  }

  body {
    padding: 60px 0 471px;
  }

  .burger_active {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
}
