@import "styles/variables.scss";

.arrow {
  width: 68px;
  height: 68px;
  border-radius: 200px;
  background: rgba(119, 182, 255, 0.3);
  border: none;
  position: fixed;
  bottom: 10vh;
  right: 50px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.3s, visibility 0s, box-shadow 0.3s ease,
    transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    transition: transform 0.3s ease;
  }
  &:focus {
    opacity: unset;
  }

  &_hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s 1s;
  }
}

//--------- MEDIA ---------

@media (max-width: 770px) {
  .arrow {
    width: 38px;
    height: 38px;
    border-radius: 200px;
    background: rgba(119, 182, 255, 0.3);
    border: none;
    position: fixed;
    bottom: 10vh;
    right: 30px;
    & img{
      width: 50%;
      height: 50%;
    }
  }
}
