@import "styles/variables.scss";

header {
  z-index: 102;

  top: 0;

  width: 100%;
  height: 60px;

  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: white;
  background-color: $projects_text;

  transition: 0.5s ease;
  @include laptop {
  }
  @include mobile {
  }
  .container {
    max-width: 1400px;
    width: 100%;
    height: 60px;

    padding: 0 40px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    @include laptop {
      max-width: 768px;
      padding: 0;
    }
    @include mobile {
      max-width: 375px;
      padding: 0 15px;
    }
    .pushKennLink {
      z-index: 102;
      display: flex;
      align-items: center;
      gap: 3px;
      span {
        color: #fff;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        text-transform: uppercase;
      }
      &:after {
        display: block;
        content: "";
        width: 11px;
        height: 10.151px;
        flex-shrink: 0;
        background-image: url("../../assets/svg/arrow_link_PushKeeen.svg");
      }
    }
    .contactLink {
      z-index: 102;
      border: 1px solid $text-light;
      border-radius: 24px;
      background-color: #fff;

      display: inline-flex;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;

      transition: 0.5s ease;
      span {
        color: $text-dark;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;

        transition: 0.5s ease;
      }
      @include laptop {
        padding: 6px 18px;
        span {
          font-size: 12px;
        }
      }
      @include mobile {
        display: none;
      }
      &:hover {
        background-color: $projects_text;
        border: 1px solid $text-light;
        span {
          color: $text-light;
        }
      }
    }
    .burger {
      z-index: 103;
      display: flex;
      width: 30px;
      height: 23px;
      flex-shrink: 0;
      content: " ";
      background-image: url("../../assets/svg/burger.svg");
      transition: 0.5s;
      button {
        width: 100%;
        height: 100%;
      }
    }
    .burger_open {
      z-index: 103;
      display: flex;
      width: 23px;
      height: 23px;
      flex-shrink: 0;
      content: " ";
      background-image: url("../../assets/svg/burger_close.svg");
      //background-color: red;
      //cursor: pointer !important;
      transition: 0.5s;
      button {
        width: 100%;
        height: 100%;
      }
    }
    .menu {
      max-width: 1400px;
      width: 100%;
      height: 516px;

      display: flex;
      justify-content: space-between;

      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);

      padding: 60px 40px;

      //background-color: red;
      @include laptop {
        height: 350px;
        max-width: 768px;
        padding: 60px 0;
      }
      @include mobile {
        height: 100%;
        max-width: 375px;
        padding: 60px 15px;
        //justify-content: flex-start;
      }
      &_left {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        @include mobile {
          //justify-content: flex-start;
          //gap: 20px;
          bottom: 0;
        }

        &_link {
          position: relative;
          display: flex;
          align-items: center;
          width: max-content;
          gap: 15px;

          color: #fff;
          font-family: IBM Plex Mono;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 48px; /* 120% */
          text-transform: uppercase;

          opacity: 0;
          transition: 0.3s ease;
          @include laptop {
            font-size: 24px;
            line-height: normal;
          }
          @include mobile {
          }
          &:after {
            display: block;
            content: "";
            width: 24px;
            height: 24px;
            background-image: url("../../assets/svg/arrow_link_desktop.svg");

            opacity: 0;
            transition: 0.3s ease;
            @include laptop {
              width: 17px;
              height: 17px;
              background-image: url("../../assets/svg/arrow_link_tablet.svg");
            }
            @include mobile {
              opacity: 1;
            }
          }
          &:hover {
            &:after {
              opacity: 1;
            }
          }
          &__disabled {
            pointer-events: none;
          }
        }

        .central_line {
          width: 1px;
          height: 0;
          background-color: #fff;

          position: absolute;
          top: 50%;
          right: -10%;
          transform: translate(0, -50%);

          transition: 0.5s ease;
          @include laptop {
          }
          @include mobile {
          }
        }
      }

      &_right {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        &_link {
          position: relative;
          display: flex;
          align-items: center;
          width: max-content;
          gap: 15px;

          color: #fff;
          font-family: IBM Plex Mono;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 48px; /* 120% */
          text-transform: uppercase;

          opacity: 0;
          transition: 0.3s ease;
          @include laptop {
            font-size: 24px;
            line-height: normal;
          }
          @include mobile {
          }

          &:after {
            display: block;
            content: "";
            width: 24px;
            height: 24px;
            background-image: url("../../assets/svg/arrow_link_desktop.svg");

            opacity: 0;
            transition: 0.3s ease;
            @include laptop {
              width: 17px;
              height: 17px;
              background-image: url("../../assets/svg/arrow_link_tablet.svg");
            }
            @include mobile {
              opacity: 1;
            }
          }
          &:hover {
            &:after {
              opacity: 1;
            }
          }
        }
      }
      .trigger {
        //background-color: red;
        //border: 2px solid blue;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);

        //max-width: 700px;
        width: 50%;
        height: 60px;
        @include laptop {
        }
        @include mobile {
          display: none;
        }

        .bottom_line {
          width: 193px;
          height: 4px;

          background: #fff;
          border-radius: 16px;

          position: absolute;
          bottom: 50%;
          left: 50%;
          transform: translate(-50%, 50%);
          @include laptop {
            width: 100px;
          }
        }
      }
    }
  }
}

.blur {
  z-index: 101;

  position: fixed;
  width: 100vw;
  height: 0;
  left: 0;
  top: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);

  transition: opacity 0.5s ease, backdrop-filter 0.5s ease;
}

.hover {
  height: 516px;
  transition: 0.5s;
  @include laptop {
    height: 350px;
  }
  @include mobile {
    height: 350px;
  }
}
