@import "styles/variables.scss";
.contact {
  //background-color: $text-blue;
  &_wrapper {
    //background-color: $text-blue;
    position: relative;
    padding-bottom: 102px;
    padding-top: 80px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .content {
      animation-iteration-count: 1;
      animation-duration: 1s;
      animation-name: slideContent;
      animation-fill-mode: forwards;
      max-width: 724px;
      gap: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .title_wrapper {
        color: $text-light;
        .title {
          display: flex;
          font-family: "IBM Plex Mono";
          font-weight: 400;
          font-size: 69px;
          line-height: 48px;
          text-transform: uppercase;
          margin-bottom: 30px;
        }
        .desc {
          font-family: "Mulish";
          font-weight: 400;
          font-size: 26px;
          line-height: 31px;
          max-width: 484px;
        }
      }

      .attach_file_wrapper {
        cursor: pointer;

        position: relative;
        background-color: rgba(255, 255, 255, 0.09);
        width: 100%;
        border-radius: 24px;
        padding: 8px 80px;
        justify-self: center;
        align-items: center;
        transition: 1s;
        & > label {
          cursor: pointer;
        }

        & .input_btn {
          border: solid 1px red;
          width: 100%;
          padding: 0 0 3px 5px;
          background: transparent;
          outline: none;
          color: $text-light;
          font-family: "Mulish";
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 32px;
          text-overflow: ellipsis;

          &:hover {
            &::placeholder {
              font-size: 25px;
              opacity: 1;
              transition: font-size 0.3s ease;
            }
          }
          &::placeholder {
            transition: font-size 0.5s ease;
            color: $text-light;
            opacity: 0.6;
          }
          &:focus {
            &::placeholder {
              opacity: 0;
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          left: 32px;
          width: 28px;
          height: 28px;
          background: url("../../assets/svg/clip.svg");
          transition: 0.5s;
        }
        .title {
          font-family: "Mulish";
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
          color: $text-light;
        }
        &:hover {
          &:before {
            transform: rotatez(90deg);
          }
        }
        .downloadFile {
          font-family: "Mulish";
          opacity: 0;
          height: 0;
          font-weight: 400;
          color: $text-light;
          margin-top: 30px;
          transition: 1s ease-in-out;

          & li {
            margin: 5px 10px;
            transition: 1s;
          }
          & span {
            font-size: 20px;
            line-height: 26px;
            transition: 1s;
          }
        }
      }

      .form {
        width: 702px;
        gap: 32px;
        .input_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          margin-bottom: 20px;

          .bracket {
            width: 100%;
            position: relative;
            padding: 0 0 0 10px;
            &::before {
              content: "";
              position: absolute;
              top: -5px;
              left: -5px;
              background: url("../../assets/svg/bracket.svg");
              width: 18px;
              height: 46px;
            }
          }

          & input {
            width: 100%;
            padding: 0 0 3px 5px;
            border-top: none;
            border-right: none;
            border-left: none;
            border-bottom: 2px solid rgba(255, 255, 255, 0.3);
            background: transparent;
            outline: none;
            color: $text-light;
            font-family: "Mulish";
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 32px;
            text-overflow: ellipsis;
            margin-bottom: 30px;

            &::placeholder {
              color: $text-light;
              opacity: 0.6;
              transition: 0.3s ease;
            }

            &:hover {
              &::placeholder {
                font-size: 25px;
                opacity: 1;
                transition: 0.3s ease;
              }
            }

            &:focus {
              &::placeholder {
                opacity: 0;
              }
            }
          }
        }

        .dropDown {
          transition: 1s;
          width: 100%;

          em {
            color: $text-light;

            font-family: Mulish;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;

            opacity: 1;
            transition: 0.3s ease;
          }
          p {
            color: $text-light;

            font-family: Mulish;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;

            opacity: 0.6;
            transition: 0.3s ease;
          }
          svg {
            fill: $text-light;
          }
          &:hover {
            em {
              font-size: 24px;
              opacity: 1;
              transition: 0.3s ease;
            }
            p {
              opacity: 1;
              transition: 0.3s ease;
            }
          }

          &_select {
            margin-top: 0;
            width: 100%;
            height: 50px;
            border-radius: 9px;
            background-color: rgba(255, 255, 255, 0.1);
            border: 2px solid rgba(255, 255, 255, 0.3);
            margin-bottom: 32px;

            transition: 1s;
          }
        }
        & textarea {
          padding: 17px 80px 0px 20px;
          resize: none;
          width: 100%;
          height: 225px;
          overflow: auto;
          text-align: left;
          background: rgba(255, 255, 255, 0.1);
          outline: none;
          font-family: "Mulish";
          font-weight: 400;
          font-size: 22px;
          line-height: 32px;
          color: $text-light;
          border: 2px solid rgba(255, 255, 255, 0.3);
          border-radius: 15px;
          scrollbar-color: $header transparent;
          scrollbar-width: thin;
          margin-bottom: 30px;
          @include mobile {
            margin-bottom: 0px;
          }

          &::-webkit-scrollbar {
            background: transparent;
            width: 6px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: $header;
          }

          &::placeholder {
            color: $text-light;
            opacity: 0.6;
            transition: opacity 0.5s ease, font-size 0.3s ease;
          }
          &:hover {
            &::placeholder {
              font-size: 25px;
              opacity: 1;
              transition: opacity 0.5s ease, font-size 0.3s ease;
            }
          }
          &:focus {
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
            &::placeholder {
              opacity: 0;
            }
          }
        }
      }
      .breif {
        background-color: $text-dark;
        color: $text-light;
        border-radius: 30px;
        padding: 10px 30px;
        border: 1px solid $text-dark;
        transition: 0.2s;
        &:hover {
          background-color: $text-blue;
          //border: 1px solid $text-dark;
        }
      }
      .label_1,
      .label_2 {
        font-family: "Mulish";
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: $text-light;
      }
      .label_2 {
        text-decoration: underline;
      }
    }

    .button_wrapper {
      width: fit-content;
      height: fit-content;

      .button {
        display: block;
        position: absolute;
        width: 324px;
        height: 324px;
        bottom: 100px;
        right: 10px;
        background: #181818;
        margin-bottom: 25px;
        transition: 2s;

        border-radius: 100%;

        .title {
          color: $text-light;
          text-transform: uppercase;
          font-family: "IBM Plex Mono";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 28px;
          transition: 2s;
        }
        &:hover {
          //animation: button-hover 2.5s ease-in-out infinite;
          transform: translate(-30px, -30px) rotate(360deg)
            translate(30px, 30px);
          .title {
            //animation: button-hover 2.5s reverse ease-in-out infinite;
            transform: translate(-30px, -30px) rotate(-360deg)
              translate(30px, 30px);
          }
        }
      }
    }

    input {
      &:invalid:not(:placeholder-shown) {
        border-color: red;
      }
    }
  }
}

.submit {
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-name: slideContent;
  animation-fill-mode: forwards;
  margin: 0 auto;

  width: 100%;
  display: flex;
  justify-content: space-between;

  .left {
    width: 25%;
    &_title {
      font-family: "IBM Plex Mono";
      font-style: normal;
      font-weight: 400;
      font-size: 47px;
      line-height: normal;
      text-transform: uppercase;
      color: $projects-text;
      margin-bottom: 20px;
    }
    &_label {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 32px;
      text-align: center;
      color: $projects-text;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 200px 100px 0 30px;
    &_title {
      font-family: IBM Plex Mono;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 126%; /* 40.32px */
      text-transform: uppercase;
      margin-bottom: 24px;
    }
    &_list {
      display: flex;
      position: relative;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      gap: 27px;

      li {
        display: flex;
        position: relative;
        align-items: center;
        gap: 20px;

        .numbering {
          display: flex;
          flex-shrink: 0;
          position: static;
          justify-content: center;
          align-items: center;
          width: 62px;
          height: 62px;
          border-radius: 100%;
          background-color: $text-light;
          opacity: 0.5; //0.24

          font-family: IBM Plex Mono;
          font-size: 38.567px;
          font-style: normal;
          font-weight: 400;
          line-height: 44.806px; /* 116.176% */
          text-transform: uppercase;
        }
        p {
          font-family: IBM Plex Mono;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  &_button {
    width: 30px;
    height: 30px;
    border: none;
    background: transparent;
    position: absolute;
    top: 30px;
    right: 35px;
    transition: 0.5s ease;

    &::after {
      content: "";
      display: block;
      background-image: url("../../assets/svg/close.svg");
      width: 32px;
      height: 32px;
      transition: 0.5s ease;
    }
    &:hover {
      &::after {
        transform: rotatex(180deg);
      }
    }
  }
}

@keyframes slideContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

//--------- MEDIA ---------

@media (max-width: 1400px) {
  .contact {
    &_wrapper {
      padding-bottom: 30px;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      .content {
        max-width: 100%;
        height: 100%;

        .title_wrapper {
          .title {
            font-size: 50px;
            line-height: 40px;
            margin-bottom: 20px;
          }
          .desc {
            font-size: 20px;
            max-width: 100%;
          }
        }
        .attach_file_wrapper {
          width: 100%;

          .downloadFile {
            margin-top: 15px;
            & li {
              margin: 5px 10px;
              font-size: 14px;
            }
            & span {
              font-size: 18px;
              line-height: 18px;
            }
          }
        }
        .form {
          width: 100%;
          .input_wrapper {
            margin-bottom: 15px;
          }
          & textarea {
            padding: 16px 60px 0 10px;
            height: 300px;
          }
        }
      }

      .button_wrapper {
        .button {
          position: relative;
          bottom: 0px;
          right: 0;
          .title {
            font-size: 19px;
            line-height: 22px;
            padding: 18px 100px;
          }
        }
      }
    }
  }

  .submit {
    flex-direction: column;
    .left {
      width: 100%;
    }
    .right {
      margin: 100px 0;
      &_list {
        width: 100%;
      }
    }
  }
}

@media (max-width: 770px) {
  .contact {
    &_wrapper {
      position: relative;
      flex-direction: column;
      align-items: center;

      .content {
        max-width: 100%;
        height: auto;
        gap: 0;
        .title_wrapper {
          .title {
            font-size: 38px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            span {
              display: none;
            }
          }
          .desc {
            font-size: 17px;
            line-height: 24px;
            max-width: 100%;
            margin-bottom: 33px;
          }
        }

        .attach_file_wrapper {
          margin: 20px 0;
          padding-right: 10px;
          .title {
            font-size: 17px;
            line-height: 24px;
            max-width: 100%;
          }
          &::before {
            top: 20px;
          }
          .downloadFile {
            & li {
              margin: 5px 10px;
              font-size: 14px;
            }
            & span {
              font-size: 18px;
              line-height: 18px;
            }
          }
        }

        .label_1,
        .label_2 {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }

      .button_wrapper {
        position: relative;
        width: 100%;
        bottom: 0;

        .button {
          width: 100%;
          height: fit-content;
          padding: 0;
          justify-content: center;
          align-items: center;
          border-radius: 20px;

          &:hover {
            transform: scale(1.1, 1);
            .title {
              transform: scale(0.9, 1);
            }
          }
          .title {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
          }
        }
      }

      .form {
        width: 100%;
        .input_wrapper {
          margin-bottom: 20px;
          & input {
            padding: 0 0 3px 24px;
            font-size: 18px;
            margin-bottom: 27px;
            &::placeholder {
              font-size: 18px;
            }
            &:hover {
              &::placeholder {
                font-size: 20px !important;
              }
            }
          }
        }
        .dropDown {
          p {
            font-size: 18px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 26px !important;
          }
          em {
            font-size: 18px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 26px !important;
          }
          &:hover {
            p {
              font-size: 20px !important;
              font-style: normal !important;
              font-weight: 400 !important;
              line-height: 26px !important;
            }
            em {
              font-size: 20px !important;
              font-style: normal !important;
              font-weight: 400 !important;
              line-height: 26px !important;
            }
          }
        }
        & textarea {
          padding: 11px 33px 0px 14px;
          height: 137px;
          font-size: 18px;
          line-height: 26px;
          &::placeholder {
            font-size: 18px;
          }
          &:hover {
            &::placeholder {
              font-size: 20px !important;
            }
          }
        }
      }
    }
  }
  .submit {
    .left {
      font-size: 20px;
      font-style: normal;
      width: 50%;
      &_title {
        font-size: 28px;
        margin-bottom: 10px;
      }
      &_label {
        font-size: 18px;
      }
    }

    .right {
      &_title {
        font-size: 28px;
        margin-bottom: 10px;
      }
      &_list {
        width: 100%;
        li {
          h3 {
            width: 48px;
            height: 48px;
            font-size: 28px;
          }
          p {
            font-size: 12px;
          }
        }
      }
    }
    &_button {
      right: 0;
    }
  }
}
