@import "styles/variables.scss";

.main {
  padding-top: 30px;

  .title_container {
    max-width: 1368px;
    margin: 0 auto 260px;
  }

  .title {
    z-index: -1;
    @include ibm;
    font-size: 285px;
    line-height: 202px;
    text-transform: uppercase;
    color: #000;
    position: relative;
    text-align: center;
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 1322px;
      border-bottom: 1px solid #000;
      bottom: -35px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .wrapper_slogan {
    position: relative;

    .slogan {
      @include ibm;
      text-transform: uppercase;
      max-width: 40%;

      opacity: 1;
      margin-bottom: 300px;
      animation-iteration-count: 1;
      animation-duration: 1s;
      animation-name: slideText;
      animation-fill-mode: forwards;

      &_text {
        font-size: 40px;
        line-height: 48px;
        color: #000;
        margin-bottom: 18px;

        .arrow {
          position: relative;
          &::after {
            content: url("../../assets/svg/slogan_arrow.svg");
            position: absolute;
            line-height: 0;
            top: calc(50% + 1px);
            left: calc(100% + 3px);
            transform: translateY(-50%);
          }
        }
        .star {
          position: relative;
          &::after {
            content: url("../../assets/svg/slogan_star.svg");
            position: absolute;
            line-height: 0;
            top: 3px;
            left: calc(100% + 3px);
            transform: translateY(50%);
          }
        }
      }

      &_desc {
        font-size: 21px;
        line-height: 27px;
        color: #999999;
      }
    }
    .rings_wrapper {
      & span,
      .rings {
        width: 100%;
      }
      width: 100%;
      height: 700px;
      position: absolute;
      top: 30px;
      transform: translateY(-50%);
      right: 0;
      z-index: -1;
    }
  }
}

.activity {
  margin-bottom: 120px;
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 113px;
  }

  &_title {
    @include ibm;
    font-size: 69px;
    line-height: 76px;
    text-transform: uppercase;
    color: #000;
  }

  &_desc {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 32px;
    color: #000;
    margin-bottom: 72px;
    max-width: 80%;
  }

  &_wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .top {
      font-size: 47px;
      position: relative;

      &::after {
        content: "";
        background: url("../../assets/svg/arrow-black.svg");
        position: absolute;
        transform: translateY(50%);
        right: -40px;
        top: 2px;
        width: 25px;
        height: 25px;
      }
    }
  }
}

.pairs {
  border-bottom: 1px solid #000;
  margin: 102px 0;
  padding-bottom: 100px;

  &_title {
    @include ibm;
    line-height: 48px;
    color: #000;
    margin-bottom: 96px;
    font-size: 69px;
    text-transform: uppercase;
  }

  &_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    justify-items: stretch;
    align-items: stretch;
    row-gap: 100px;

    &_card {
      display: flex;
      min-height: 207px;
      align-items: center;
      justify-content: center;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      transition: filter 0.4s;
      &:hover {
        transition: filter 0.4s;
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
      }
      & img {
        object-fit: contain;
        max-width: 100%;
      }
    }
  }
}

.button_more {
  display: flex;
  justify-content: center;
  padding: 5px 0;
  color: #1858fb;
  @include mulish;
  font-size: 26px;
  line-height: 31px;
  @include hoveredText;
  &:hover {
    background-size: 100% 1.5px;
    @include mobile {
      background-size: 0% 1.5px;
    }
  }
  @include mobile {
    margin-top: 28px;
    @include ibm;
    font-size: 20px;
    line-height: 28px;
    background-color: $text-blue;
    border-radius: 14px;
    color: #ffffff;
    text-transform: uppercase;
    border: none;
    padding: 10px 0;
    width: 100%;
  }
}

.projects {
  margin-bottom: 65px;

  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  &_title {
    @include ibm;
    font-size: 68px;
    line-height: 79px;
    text-transform: uppercase;
    color: #000;
  }
  &_box {
    display: flex;
    flex-direction: column;
    gap: 56px;
    @include laptop {
      gap: 30px;
    }
    @include mobile {
      gap: 119px;
      padding-bottom: 100px;
    }
    & > a:nth-child(2n-1) > div {
      justify-content: flex-end;
    }
  }
}

@keyframes slideText {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

//--------- MEDIA ---------

@media (max-width: 1400px) {
  .main {
    padding-top: 50px;

    .title_container {
      max-width: 768px;
      margin: 0 auto 120px;
    }

    .title {
      font-size: 162px;
      line-height: 170px;

      &::after {
        width: 768px;
        bottom: -20px;
      }
    }

    .wrapper_slogan {
      .slogan {
        margin-bottom: 120px;

        &_text {
          font-size: 27px;
          line-height: 40px;
          margin-bottom: 18px;
        }

        &_desc {
          font-size: 18px;
          line-height: 23px;
        }
      }
    }
  }

  .projects {
    &_title {
      font-size: 50px;
      line-height: 40px;
    }
  }

  .activity {
    margin-bottom: 70px;
    &_header {
      margin-bottom: 50px;
    }

    &_title {
      font-size: 50px;
      line-height: 40px;
    }

    &_desc {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 33px;
    }
  }

  .pairs {
    margin: 80px 0;
    padding-bottom: 80px;

    &_title {
      line-height: 40px;
      margin-bottom: 50px;
      font-size: 50px;
    }

    &_grid {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
    }
  }
}

@media (max-width: 770px) {
  .main {
    padding-top: 4vw;

    .title_container {
      max-width: 100%;
      margin: 0 auto 0px;
    }

    .title {
      font-size: 19vw;
      // font-size: 74px;
      line-height: 20vw;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        bottom: -9px;
      }
    }

    .wrapper_slogan {
      padding-top: 300px;
      .slogan {
        max-width: 100%;
        margin-bottom: 40px;
        margin-top: -20px;
        &_text {
          font-size: 23px;
          line-height: 32px;
          margin-bottom: 5px;

          .arrow {
            &::after {
              content: url("../../assets/svg/mobile/arrow.svg");
            }
          }
          .star {
            &::after {
              content: url("../../assets/svg/mobile/star.svg");
            }
          }
        }

        &_desc {
          font-size: 14px;
        }
      }

      .rings_wrapper {
        top: 120px;
      }
    }
  }

  .projects {
    margin-bottom: 0px;
    padding-bottom: 115px;
    &_wrapper {
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      .projects_title {
        line-height: 50px;
      }
    }

    .button_more {
      display: block;
    }

    &_title {
      font-size: 38px;
      line-height: 79px;
      margin-bottom: 0px;
    }
  }

  .activity {
    padding: 40px 0 57px;
    border-radius: 16px;
    background: rgba(24, 88, 251, 0.1);
    &_header {
      margin-bottom: 30px;
    }

    &_title {
      font-size: 38px;
      line-height: 48px;
    }

    &_desc {
      font-size: 17px;
      line-height: 24px;
      margin-bottom: 28px;
      max-width: 100%;
    }
  }

  .pairs {
    border-bottom: none;
    margin: 53px 0;
    padding-bottom: 0;

    &_title {
      margin-bottom: 5px;
      font-size: 38px;
      line-height: 79px;
    }
    &_grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;

      &_card {
        display: flex;
        min-height: 200px;
        align-items: center;
        justify-content: center;
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
        transition: none;
        &:hover {
          transition: none;
        }
      }
    }
  }
  @keyframes slideText {
    0% {
      opacity: 0;
      transform: translateX(10%);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }
}
