@import "styles/variables.scss";

.projectCardContainer {
  display: flex;
  text-transform: uppercase;
  color: #181818;
}

.projectCard {
  padding: 26px;
  border-radius: 24px;
  width: 1098px;
  height: 406px;
  display: flex;
  justify-content: space-between;
}

.projectCard:hover img {
  transform: scale(1.3);
}

.projectCard img {
  transition: transform 0.7s ease;
}

.projectCard:hover p {
  background-size: 100% 1.5px;
}

.projectCard p {
  @include hoveredText;
  display: inline;
}

// .projectCard:hover .topRightSpan {
//   background-color: #1858fb;
//   border: #1858fb 1px solid;
//   color: #ffffff;
// }

.projectCard .topRightSpan {
  transition: background-color 0.3s, border 0.3s, color 0.3s;
}

.firstGrad {
  background: linear-gradient(
      157deg,
      #5771be 0%,
      #4551c0 33.33%,
      #5c68cb 73.54%,
      #90a9f4 100%
  );
}

.secondGrad {
  background: linear-gradient(
      81deg,
      #03C066 14.77%,
      #55E29E 68.65%,
      #70D7A6 99.49%
  );
}

.thirdGrad {
  background: linear-gradient(
      81deg,
      #0177BF 14.77%,
      #83DBFA 68.65%,
      #ACEBFC 99.49%
  );
}

.fourthGrad {
  background: linear-gradient(
      80deg,
      #F25928 14.65%,
      #F6874F 62.47%,
      #F89C65 98.44%
  );
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonsContainer {
  display: flex;
}

.topLeftSpan,
.topRightSpan {
  margin: 5px;
  border-radius: 24px;
  font-family: "IBM Plex Mono", sans-serif;
  white-space: nowrap;
  align-self: center;
  padding: 2px 18px;
  font-size: 20px;
}

.topRightSpan,
.topLeftSpan {
  color: #ffffff;
  border: #ffffff 1px solid;
}

.bottomLeftText {
  font-family: "IBM Plex Mono", sans-serif;
  font-size: 44px;
  white-space: normal;
  color: #ffffff;
  width: 536px;
}

.gidroImg {
  width: 550px;
  height: 363px;
  margin-left: 120px;
}

.gorodaImg {
  width: 393px;
  height: 402px;
  margin: -20px 60px;
}

.ncdImg {
  width: 417px;
  height: 390px;
  margin: -15px 45px;
}

.trainImg {
  width: 439px;
  height: 499px;
  margin: -65px 40px;
  //border: 2px black solid;
}

/*--------- MEDIA ---------*/

@media (max-width: 1400px) {
  .projectCardContainer {
    border-radius: 16px;
    align-items: center;
  }

  .projectCardContainer:nth-child(even) {
    margin-left: 0;
  }

  .projectCard {
    flex-direction: row;
    width: 100%;
    height: auto;
  }

  .topLeftSpan,
  .topRightSpan {
    font-size: 1rem;
  }

  .bottomLeftText {
    font-size: 2rem;
    width: 80%;
    @include mobile {
      color: black;
    }
  }

  .gidroImg {
    width: 40%;
    height: auto;
    align-self: center;
    margin-right: 100px;
  }

  .gorodaImg {
    width: 30%;
    height: auto;
    margin-right: 150px;
  }

  .ncdImg {
    width: 30%;
    height: auto;
    margin-right: 100px;
  }
  .trainImg {
    width: 30%;
    height: auto;
    margin: -20px;
    margin-right: 100px;
  }
}

@media (max-width: 770px) {
  .projectCardContainer {
    border-radius: 13px;
    align-items: center;
  }

  .projectCard {
    flex-direction: column;
    height: auto;
    position: relative;
  }

  .left {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    margin-left: -30px;
  }

  .topLeftSpan,
  .topRightSpan {
    font-size: 0.6rem;
    color: #181818;
    background: none;
    border: #181818 1px solid;
  }

  .bottomLeftText {
    font-size: 26px;
    line-height: 28px;
    width: 100%;
  }

  .gidroImg {
    width: 90%;
    margin-right: 60px;
  }

  .gorodaImg {
    width: 90%;
    height: auto;
    align-self: center;
    margin: 0;
  }

  .ncdImg {
    width: 90%;
    height: auto;
    align-self: center;
    margin: 0;
  }

  .trainImg {
    width: 90%;
    height: auto;
    align-self: center;
    margin: 0;
  }

  .thirdGrad {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.1) 100%
    ),
    linear-gradient(144deg, #f7b529 0%, #ed7d01 97.92%);
  }
}
