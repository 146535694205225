@import "styles/variables.scss";

.wr {
  padding: 143px 0;
  background: linear-gradient(
      0deg,
      rgba(212, 228, 255, 0.2) 0%,
      rgba(212, 228, 255, 0.2) 100%
    ),
    #fff;
  @include vela7;
  background-image: url("/assets/dobroar/bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  @include laptop {
    background-image: url("/assets/dobroar/bg-laptop.png");
  }
  @include mobile {
    background-image: none;
    padding: 151px 0 60px;
  }
  .btn2 {
    width: 692px;
    margin: 45px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 21px;
    border-radius: 15px;
    background-color: #2979ff;
    color: #fff;
    @include vela7;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
    @include laptop {
      width: 622px;
    }
    @include mobile {
      width: 90%;
    }
    & path {
      transition: stroke 0.3s;
    }
    &:hover {
      background-color: #bfd7ff;
      color: #153d80;
      & path {
        stroke: #153d80;
      }
    }
    &:active {
      background-color: #153d80;
      color: #fff;
      & path {
        stroke: #fff;
      }
    }
    @include laptop {
      padding: 17px;
    }
    @include mobile {
      padding: 10px;
      font-size: 16px;
    }
  }
}

.sq1 {
  margin: 0 auto;
  width: 692px;
  height: 714px;
  border-radius: 30px;
  border: 2px solid rgba(41, 121, 255, 0.3);
  background-color: #fff;
  padding: 29px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url("/assets/dobroar/stars.png");
  background-repeat: no-repeat;
  background-position: center 5%;
  background-size: 90%;
  @include laptop {
    width: 623px;
    height: 653px;
  }
  @include mobile {
    width: 90%;
    height: auto;
    background-size: 90% 14.6vw;
    padding-top: 83px;
    position: relative;
    & > img {
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translate(-50%, -100%);
    }
  }
}

.sq2 {
  margin: 45px auto;
  width: 692px;
  height: fit-content;
  border-radius: 30px;
  border: 2px solid rgba(41, 121, 255, 0.3);
  background-color: #fff;
  padding: 50px 20px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: flex-start;
  background-repeat: no-repeat;
  background-position: center 5%;
  background-size: 90%;
  @include laptop {
    width: 623px;
  }
  @include mobile {
    width: 90%;
    height: auto;
    background-size: 90% 14.6vw;
    gap: 25px;
    padding-top: 30px;
    position: relative;
  }
  .text {
    gap: 15px;
    margin-bottom: 0;
  }
  .image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 55px;
      height: 40px;
      @include mobile {
        transform: scale(0.625);
      }

      //transform: translate(-50%, -100%);
    }
  }

  .partners {
    width: 100%;
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    gap: 80px;
    @include laptop {
      margin-top: -10px;
    }
    @include mobile {
      gap: 30px;
      margin-top: 15px;
      & img {
        max-width: 35.2vw;
        object-fit: contain;
      }
    }
  }
}

.newyear {
  color: #2979ff;
  font-size: 80px;
  @include zero;
  line-height: 100%;
  letter-spacing: 2px;
  margin-bottom: 40px;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  padding-left: 14px;
  @include laptop {
    font-size: 71px;
    letter-spacing: 2px;
  }
  @include mobile {
    font-size: 9vw;
    padding-left: 10px;
    margin-bottom: 30px;
  }
}

.title {
  color: #103066;
  font-size: 26px;
  padding-left: 14px;
  line-height: 138%;
  margin-bottom: 15px;
  @include laptop {
    font-size: 22px;
    line-height: 145%;
  }
  @include mobile {
    font-size: 4.8vw;
    padding-left: 10px;
  }
}

.text {
  color: rgba(16, 48, 102, 0.85);
  padding-left: 14px;
  @include vela5;
  font-size: 20px;
  line-height: 160%;
  margin-bottom: 63px;
  @include laptop {
    font-size: 18px;
    margin-bottom: 55px;
  }
  @include mobile {
    font-size: 4.26vw;
    margin-bottom: 40px;
    padding-left: 10px;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 21px;
  width: 100%;
  border-radius: 15px;
  background-color: #2979ff;
  color: #fff;
  @include vela7;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
  & path {
    transition: stroke 0.3s;
  }
  &:hover {
    background-color: #bfd7ff;
    color: #153d80;
    & path {
      stroke: #153d80;
    }
  }
  &:active {
    background-color: #153d80;
    color: #fff;
    & path {
      stroke: #fff;
    }
  }
  @include laptop {
    padding: 17px;
  }
  @include mobile {
    padding: 10px;
    font-size: 4vw;
  }
}
