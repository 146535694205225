@import "styles/variables.scss";

.desc {
  margin-bottom: 80px;
  padding-top: 30px;

  .button {
    font-family: "IBM Plex Mono";
    font-style: normal;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    line-height: 52px;
    color: #fff;
    background: $text-blue;
    border-radius: 100px;
    margin-bottom: 49px;
    padding: 0 20px;
    border: none;
    transition: box-shadow 0.3s ease, transform 0.3s ease;

    &:hover {
      box-shadow: $box-shadow;
      transform: translateY(-5px);
      transition: box-shadow 0.3s ease, transform 0.3s ease;
    }
  }
  .title {
    font-weight: 400;
    font-size: 69px;
    line-height: 69px;
    color: #000;
    border-bottom: 1px solid #000;
    margin-bottom: 23px;
    padding-bottom: 17px;
  }
  .text {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 29px;
    color: #000;
  }
}

.collections {
  margin-bottom: 99px;
  .title {
    margin-bottom: 60px;
  }
}

.title {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 68px;
  line-height: 79px;
  text-transform: uppercase;
  color: #000;
}

//-------MEDIA-------

@media (max-width: 1400px) {
  .desc {
    margin-bottom: 70px;

    .text {
      font-size: 22px;
      line-height: 25px;
    }
  }

  .collections {
    margin-bottom: 80px;
    .title {
      margin-bottom: 50px;
    }
  }

  .title {
    font-size: 50px;
    line-height: 40px;
  }
}

@media (max-width: 770px) {
  .desc {
    margin-bottom: 75px;
    padding-top: 23px;

    .button {
      display: none;
    }
    .title {
      font-size: 38px;
      line-height: 48px;
      margin-bottom: 20px;
      padding-bottom: 4px;
    }
    .text {
      font-size: 17px;
      line-height: 24px;
    }
  }

  .collections {
    margin-bottom: 33px;
    .title {
      display: none;
    }
  }
}
