.pairsSlider {
  width: 100%;
  margin: 30px 0 0 0;
  &::before,
  &::after {
    content: "";
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 125px;
    background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  }
  &::after {
    right: 0;
    left: initial;
    background: linear-gradient(-90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  }
  .slide_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // height: 368px;

    .slide {
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        object-fit: contain;
        max-height: 100%;
      }

      &_1 {
        height: 56px;
        margin-bottom: 68px;
      }
      &_2 {
        height: 70px;
        margin-bottom: 75px;
      }
      &_3 {
        height: 35px;
      }
      &_4 {
        height: 102px;
        margin-bottom: 46px;
      }
      &_5 {
        height: 68px;
        margin-bottom: 60px;
      }
      &_6 {
        height: 66px;
      }
      &_7 {
        height: 82px;
        margin-bottom: 50px;
      }
      &_8 {
        height: 79px;
        margin-bottom: 40px;
      }
      &_9 {
        height: 96px;
      }
    }
  }
}
