@import "styles/variables.scss";

.streetart {
  .about {
    color: $projects_text;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &_title {
      width: 50%;
      padding-bottom: 30px;
      position: relative;
      margin-bottom: 33px;

      &_name {
        font-family: "IBM Plex Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 68px;
        line-height: 79px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      &_label {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 544px;
        border-bottom: 1px solid #000;
        bottom: 0;
      }
    }
    &_content {
      width: 48%;
      line-height: 31px;
      &_name {
        @include ibm;
        font-size: 28px;
        margin-bottom: 27px;
      }
      &_label {
        font-style: normal;
        font-weight: 400;
        font-family: "Mulish";
        font-size: 24px;
      }
    }
  }
  .buttons {
    max-width: 50%;
    margin-bottom: 96px;
    margin-top: -100px;

    .button_1,
    .button_2 {
      @include ibm500;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: $projects_text;
      border: 1px solid $projects_text;
      background-color: transparent;
      border-radius: 100px;
      display: inline-block;
      padding: 13px 20px;
      margin-bottom: 10px;
    }

    .button_1 {
      margin-right: 10px;
    }
  }

  .image_wrapper {
    background-color: #d8f3ff;
    text-align: center;
    margin-bottom: 58px;
    .image_box {
      object-fit: contain;
      max-width: 100%;
    }
  }

  .task {
    width: 100%;
    margin-bottom: 96px;
    color: $projects_text;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 40px;

    &_wrapper {
      max-width: 75%;
      .task_title,
      .point_title {
        font-family: "IBM Plex Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 31px;
        margin-bottom: 27px;
      }
      .task_text {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 56px;
        max-width: 70%;
      }

      .point_text {
        & li {
          font-family: "IBM Plex Mono";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 31px;
          position: relative;
          padding-left: 32px;
          &::before {
            content: "";
            position: absolute;
            display: block;
            border-radius: 100px;
            background-color: $secondary-blue;
            width: 20px;
            height: 20px;
            top: 5px;
            left: 0;
          }
        }
      }
    }
    &_date {
      position: relative;

      .number {
        display: block;
        padding: 16px 35px;
        background: rgba(119, 182, 255, 0.3);
        border-radius: 100px;
        font-family: "IBM Plex Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 68px;
        line-height: 79px;
        text-transform: uppercase;
        color: $text-blue;
        width: min-content;
      }
      .month,
      .label {
        position: absolute;
        font-family: "IBM Plex Mono";
        font-style: normal;
        font-weight: 400;
      }
      .month {
        right: -40px;
        bottom: 40%;
        transform: translateY(50%);
        font-size: 32px;
        line-height: 42px;
      }
      .label {
        bottom: -10px;
        right: -20px;
        font-size: 20px;
        line-height: 26px;
        white-space: nowrap;
      }
    }
  }

  &_image_1,
  &_image_2 {
    max-width: 100%;
    object-fit: contain;
  }
  &_image_1 {
    margin-bottom: 24px;
  }
  &_image_2 {
    margin-bottom: 70px;
  }

  .results {
    margin-bottom: 50px;
    width: 50%;
    &_title,
    &_text {
      color: $projects_text;
      font-style: normal;
      font-weight: 400;
      line-height: 31px;
    }

    &_title {
      font-family: "IBM Plex Mono";
      font-size: 28px;
      margin-bottom: 27px;
    }
    &_text {
      font-family: "Mulish";
      font-size: 24px;
    }
  }
}

//-------MEDIA-------

@media (max-width: 1400px) {
  .streetart {
    .about {
      margin-bottom: 20px;
      &_title {
        &::after {
          width: 350px;
        }
        &_name {
          font-size: 55px;
          line-height: 60px;
        }
      }
      &_content {
        width: 50%;
        line-height: 31px;
      }
    }
    .buttons {
      max-width: 50%;
      margin-bottom: 120px;
      margin-top: -200px;
      & .button_1 {
        margin-bottom: 10px;
      }
    }
  }
}
@media (max-width: 770px) {
  .streetart {
    .streetart_image {
      margin-bottom: 40px;
    }
    .about {
      margin-bottom: 40px;
      flex-direction: column;

      &_title {
        width: 100%;
        padding-bottom: 20px;
        margin-bottom: 25px;

        &_name {
          font-size: 50px;
          line-height: 60px;
        }
        &_label {
          font-size: 14px;
          line-height: 20px;
        }

        &::after {
          width: 100%;
        }
      }
      &_content {
        width: 100%;
        &_name {
          font-size: 23px;
          margin-bottom: 20px;
        }
        &_label {
          font-size: 20px;
        }
      }
    }
    .buttons {
      margin-bottom: 70px;
      margin-top: 0;
      max-width: 100%;
      .button_1,
      .button_2 {
        width: 100%;
        text-align: center;
      }

      .button_1 {
        margin-bottom: 10px;
      }
    }
    .task {
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      padding-right: 0;
      &_text {
        max-width: 100%;
      }
      &_wrapper {
        max-width: 100%;
        .point_text {
          font-size: 20px;
          margin-bottom: 40px;
          word-wrap: break-word;
        }
        .task_text {
          max-width: 100%;
        }
      }
      &_date {
        margin-right: 35px;
        align-self: flex-end;
      }
    }
    .results {
      width: 100%;
    }
  }
}
