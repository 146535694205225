@import "styles/variables.scss";

.mymoscow {
  .result_content {
    width: 50%;
    line-height: 31px;
    margin-bottom: 53px;
    &_name {
      font-style: normal;
      font-weight: 400;
      font-family: "IBM Plex Mono";
      font-size: 28px;
      margin-bottom: 27px;
    }
    &_label {
      font-style: normal;
      font-weight: 400;
      font-family: "Mulish";
      font-size: 24px;
    }
  }
  .tasks {
    margin-bottom: 33px;
    color: $projects_text;
    display: flex;
    align-items: flex-start;

    &_title {
      width: 50%;
      padding-bottom: 30px;
      position: relative;

      &_name {
        @include ibm;
        font-size: 68px;
        line-height: 79px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      &_label {
        font-style: normal;
        font-weight: 400;
        font-family: "Mulish";
        font-size: 20px;
        line-height: 25px;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 544px;
        border-bottom: 1px solid #000;
        bottom: 0;
      }
    }

    &_content {
      width: 50%;

      line-height: 31px;
      &_name {
        @include ibm;
        font-size: 28px;
        margin-bottom: 27px;
      }
      &_label {
        font-style: normal;
        font-weight: 400;
        font-family: "Mulish";
        font-size: 24px;
      }
    }
  }
  .buttons {
    margin-bottom: 102px;
    margin-top: -40px;
    max-width: 40%;

    .button_2,
    .button_3,
    .button_4,
    .button_5,
    .button_6 {
      @include ibm500;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: $projects_text;
      border: 1px solid $projects_text;
      background-color: transparent;
      border-radius: 100px;
      display: inline-block;
      padding: 13px 20px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  .img_wrapper_1,
  .img_wrapper_2,
  .img_wrapper_3,
  .img_wrapper_5 {
    background-color: #fff;
    text-align: center;
    position: relative;
  }
  .img_wrapper_1,
  .img_wrapper_2 {
    margin-bottom: 86px;
  }
  .img_wrapper_3 {
    margin-bottom: 133px;
  }
  .img_wrapper_5 {
    margin-bottom: 72px;
  }
  .img_wrapper_5 {
    & img {
      &:first-child {
        margin-bottom: 24px;
      }
    }
  }
  .video_wrapper {
    position: relative;
    margin-bottom: 134px;
    & video {
      object-fit: contain;
      max-width: 100%;
    }
  }
  .steps {
    padding-right: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 146px;

    &_text {
      .title,
      .ecosystem {
        font-family: "IBM Plex Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 31px;
        color: $projects_text;
        margin-bottom: 27px;
      }
      .text {
        margin-bottom: 74px;
        max-width: 60%;
      }
      .text,
      .ecosystem_list {
        color: $projects_text;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 31px;
      }
      .ecosystem {
        margin-bottom: 28px;
      }
      .ecosystem_list {
        max-width: 100%;

        & li {
          font-family: "IBM Plex Mono";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 31px;
          position: relative;
          padding-left: 32px;
          &::before {
            content: "";
            position: absolute;
            display: block;
            border-radius: 100px;
            background-color: $secondary-blue;
            width: 20px;
            height: 20px;
            top: 5px;
            left: 0;
          }
        }
      }
    }
    &_date {
      position: relative;

      .number {
        display: block;
        padding: 16px 35px;
        background: rgba(119, 182, 255, 0.3);
        border-radius: 100px;
        font-family: "IBM Plex Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 68px;
        line-height: 79px;
        text-transform: uppercase;
        color: $text-blue;
        width: min-content;
      }
      .month,
      .label {
        position: absolute;
        font-family: "IBM Plex Mono";
        font-style: normal;
        font-weight: 400;
      }
      .month {
        right: -40px;
        bottom: 40%;
        transform: translateY(50%);
        font-size: 32px;
        line-height: 42px;
      }
      .label {
        bottom: -10px;
        right: -20px;
        font-size: 20px;
        line-height: 26px;
        white-space: nowrap;
      }
    }
  }
  //---shared---
  .circle {
    width: 17px;
    height: 17px;
    background: $text-blue;
    border-radius: 50px;
    display: inline-block;
    margin-left: 8px;
    padding-top: 15px;
  }
  .img_label {
    position: absolute;
    top: -16px;
    transform: translateY(-100%);
    right: 0;
    display: flex;
    align-items: center;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: right;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $projects_text;
  }
  .image {
    object-fit: contain;
    width: 100%;
  }
}

//-------MEDIA-------

@media (max-width: 1400px) {
  .mymoscow {
    .tasks {
      &_title {
        padding-bottom: 50px;
        &_name {
          font-size: 60px;
        }
        &::after {
          width: 350px;
        }
      }
      &_content {
        &_label {
          font-size: 22px;
        }
      }
    }
    .buttons {
      margin-bottom: 90px;
      margin-top: 0px;
      max-width: 100%;
      & button {
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }

    .steps {
      padding-right: 0px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: 100px;

      &_text {
        margin-bottom: 30px;
        width: 100%;
      }
      &_date {
        align-self: flex-end;
        margin-right: 40px;

        .number {
          display: block;
          padding: 16px 35px;
          background: rgba(119, 182, 255, 0.3);
          border-radius: 100px;
          font-family: "IBM Plex Mono";
          font-style: normal;
          font-weight: 400;
          font-size: 68px;
          line-height: 79px;
          text-transform: uppercase;
          color: $text-blue;
          width: min-content;
        }
        .month,
        .label {
          position: absolute;
          font-family: "IBM Plex Mono";
          font-style: normal;
          font-weight: 400;
        }
        .month {
          right: -40px;
          bottom: 40%;
          transform: translateY(50%);
          font-size: 32px;
          line-height: 42px;
        }
        .label {
          bottom: -10px;
          right: -20px;
          font-size: 20px;
          line-height: 26px;
          white-space: nowrap;
        }
      }
    }

    .results {
      width: 100%;
    }
  }
}
@media (max-width: 770px) {
  .mymoscow {
    .mymoscow_image {
      margin-bottom: 40px;
    }
    .result_content {
      width: 100%;
    }
    .tasks {
      margin-bottom: 40px;
      flex-direction: column;

      &_title {
        width: 100%;
        padding-bottom: 20px;
        margin-bottom: 25px;

        &_name {
          font-size: 40px;
          line-height: 60px;
        }
        &_label {
          font-size: 14px;
          line-height: 20px;
        }

        &::after {
          width: 100%;
        }
      }
      &_content {
        width: 100%;
        &_name {
          font-size: 23px;
          margin-bottom: 20px;
        }
        &_label {
          font-size: 20px;
          line-height: 25px;
        }
      }
    }
    .buttons {
      margin-bottom: 70px;
      margin-top: 0px;
      max-width: 100%;
      & button {
        &:first-child {
          &::after {
            right: 90px;
          }
        }
      }
      .button_2,
      .button_3,
      .button_4,
      .button_5,
      .button_6 {
        width: 100%;
        text-align: center;
      }
    }
    .steps {
      margin-bottom: 150px;

      &_text {
        .text {
          max-width: 100%;
        }
      }
    }
  }
}
