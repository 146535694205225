$header: #161414;
$text-light: #fff;
$text-dark: #000;
$placeholder: #a9a9a9;
$base-blue: #0047ff;
$secondary-blue: #0f4cea;
$text-blue: #1858fb;
$projects_text: #181818;

$text-shadow: 0 0 10px $text-blue, 0 0 20px $text-blue, 0 0 30px $text-blue,
  0 0 40px $text-blue;
$box-shadow: 0 0 2px $text-blue, 0 0 5px $text-blue, 0 0 10px $text-blue,
  0 0 20px $text-blue;

@mixin ibm {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
}

@mixin ibm500 {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
}
@mixin ibm600 {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 600;
}
@mixin ibm700 {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 700;
}

@mixin mulish {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
}

@mixin zero {
  font-family: "Zero Cool";
  font-style: normal;
  font-weight: 400;
}

@mixin vela5 {
  font-family: "Vela Sans";
  font-style: normal;
  font-weight: 500;
}

@mixin vela7 {
  font-family: "Vela Sans";
  font-style: normal;
  font-weight: 700;
}

@mixin hoveredText {
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1.5px;
  transition: background-size 0.5s;
}
// --- BREAKPOINTS ---

$desktop-width: 1400px;
$mobile-width: 770px;

@mixin laptop {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}
