@import "styles/variables.scss";

.newcity {
  .about {
    color: $projects_text;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 33px;

    &_title {
      width: 40%;
      padding-bottom: 10px;
      position: relative;

      &_name {
        font-family: "IBM Plex Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 68px;
        line-height: 79px;
        text-transform: uppercase;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 544px;
        border-bottom: 1px solid #000;
        bottom: 0;
      }
    }
    &_content {
      width: 50%;
      line-height: 31px;
      &_name {
        @include ibm;
        font-size: 28px;
        margin-bottom: 27px;
      }
      &_label {
        max-width: 90%;
        font-style: normal;
        font-weight: 400;
        font-family: "Mulish";
        font-size: 24px;
        &_link {
          color: $text-blue;
          transition: opacity 0.3s;
          &:hover {
            opacity: 0.5;
            transition: opacity 0.3s;
          }
        }
      }
    }
  }
  .buttons {
    max-width: 50%;
    margin-bottom: 160px;
    margin-top: -80px;
    .button_1 {
      @include ibm500;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: $projects_text;
      border: 1px solid $projects_text;
      background-color: transparent;
      border-radius: 100px;
      display: inline-block;
      padding: 13px 20px;
    }
  }
  .desc,
  .desc_2 {
    margin: 0 auto 96px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    color: $projects_text;
  }
  .desc_2 {
    max-width: 80%;
    text-align: center;
  }
  .car_image {
    margin-bottom: 96px;
    max-width: 100%;
    object-fit: contain;
  }
  .video_wrapper_2,
  .video_wrapper_3,
  .video_wrapper_4 {
    margin-bottom: 96px;
  }
  .video_wrapper_1 {
    margin-bottom: 56px;
  }
  .video_wrapper_1,
  .video_wrapper_2,
  .video_wrapper_4,
  .video_wrapper_3 {
    & video {
      object-fit: contain;
      max-width: 100%;
    }
  }
  .point_title {
    @include ibm;
    font-size: 28px;
    line-height: 31px;
    margin-bottom: 27px;
  }

  .point_text {
    max-width: 50%;
    margin-bottom: 56px;

    & li {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 31px;
      position: relative;
      padding-left: 32px;
      &::before {
        content: "";
        position: absolute;
        display: block;
        border-radius: 100px;
        background-color: $secondary-blue;
        width: 20px;
        height: 20px;
        top: 7px;
        left: 0;
      }
    }
  }
}

//-------MEDIA-------

@media (max-width: 1400px) {
  .newcity {
    .about {
      margin-bottom: 20px;
      &_title {
        &::after {
          width: 350px;
        }
        &_name {
          font-size: 55px;
          line-height: 60px;
        }
      }
      &_content {
        &_label {
          font-size: 20px;
        }
        width: 50%;
        line-height: 31px;
      }
    }
    .buttons {
      margin-top: -200px;
      margin-bottom: 190px;
      & button {
        margin-bottom: 10px;
      }
    }
    .video_wrapper {
      margin-bottom: 30px;
    }
    .point_text {
      max-width: 100%;
    }
  }
}
@media (max-width: 770px) {
  .newcity {
    .newcity_image {
      margin-bottom: 40px;
    }
    .about {
      margin-bottom: 40px;
      flex-direction: column;

      &_title {
        width: 100%;
        padding-bottom: 20px;
        margin-bottom: 25px;

        &_name {
          font-size: 50px;
          line-height: 60px;
        }

        &::after {
          width: 100%;
        }
      }
      &_content {
        width: 100%;
        &_name {
          font-size: 23px;
          margin-bottom: 20px;
        }
        &_label {
          font-size: 20px;
        }
      }
    }
    .buttons {
      max-width: 100%;
      margin-bottom: 40px;
      margin-top: 0;
      .button_1 {
        width: 100%;
        text-align: center;
      }
    }
    .video_wrapper_1 {
      margin-bottom: 40px;
    }
    .desc {
      margin-bottom: 50px;
    }
    .desc_2 {
      max-width: 100%;
    }
    .car_image,
    .desc_2,
    .video_wrapper_2,
    .video_wrapper_3,
    .video_wrapper_4 {
      margin-bottom: 20px;
    }
    .desc {
      font-size: 22px;
      line-height: 28px;
      max-width: 95%;
    }
  }
}
