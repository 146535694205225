@import "../../styles/variables.scss";

.project {
  padding-top: 29px;
  .nav_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 49px;

    .link_wrapper {
      margin-right: 62px;
    }
  }

  &_button {
    font-family: "IBM Plex Mono";
    font-style: normal;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    line-height: 52px;
    color: #fff;
    background: $text-blue;
    border-radius: 100px;
    padding: 0 20px;
    border: none;
    transition: box-shadow 0.3s ease, transform 0.3s ease;

    &:hover {
      box-shadow: $box-shadow;
      transform: translateY(-5px);
      transition: box-shadow 0.3s ease, transform 0.3s ease;
    }
  }

  .breadcrumb {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 31px;
    color: $projects_text;
    & li {
      display: inline-block;
    }
  }
}

//--------MEDIA--------

@media (max-width: 770px) {
  .project {
    .nav_wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 30px;

      .link_wrapper {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }
  }
}
