@import "styles/variables.scss";

.interior {
  .about {
    color: $projects_text;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0px;

    &_title {
      width: 50%;
      padding-bottom: 50px;
      position: relative;
      @include ibm;
      font-size: 68px;
      line-height: 79px;
      text-transform: uppercase;
      margin-bottom: 33px;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 544px;
        border-bottom: 1px solid #000;
        bottom: 0;
      }
    }
    &_content {
      width: 50%;
      line-height: 31px;
      &_name {
        @include ibm;
        font-size: 28px;
        margin-bottom: 27px;
      }
      &_label {
        font-style: normal;
        font-weight: 400;
        font-family: "Mulish";
        font-size: 24px;
      }
      .link {
        color: $text-blue;
        transition: opacity 0.3s;
        &:hover {
          opacity: 0.6;
          transition: opacity 0.3s;
        }
      }
    }
  }
  .button_nft {
    max-width: 45%;
    margin-bottom: 96px;
    & span {
      @include ibm500;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: $projects_text;
      border: 1px solid $projects_text;
      background-color: transparent;
      border-radius: 100px;
      display: inline-block;
      padding: 13px 20px;
    }
  }
  .text_1,
  .text_2 {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    color: $projects_text;
    margin: 96px 0;
  }
  .tokens {
    margin: 96px 0;
    max-width: 50%;
    &_title {
      @include ibm;
      font-size: 28px;
      line-height: 31px;
      color: $projects_text;
      margin-bottom: 28px;
    }
    &_text {
      & li {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 31px;
        color: $projects_text;
        position: relative;
        padding-left: 32px;
        margin-bottom: 4px;
        &::before {
          content: "";
          position: absolute;
          display: block;
          border-radius: 100px;
          background-color: $secondary-blue;
          width: 20px;
          height: 20px;
          top: 5px;
          left: 0;
        }
      }
    }
  }
  .video_wrapper {
    margin-bottom: 30px;
    & video {
      width: 100%;
      // height: 900px;
      object-fit: contain;
    }
  }
}

//-------MEDIA-------

@media (max-width: 1400px) {
  .interior {
    &_image {
      margin-bottom: 53px;
    }
    .about {
      &_title {
        width: 50%;
        padding-bottom: 50px;
        font-size: 48px;
        line-height: 59px;
        margin-bottom: 0px;

        &::after {
          width: 350px;
        }
      }
      &_content {
        &_label {
          font-size: 18px;
        }
        width: 50%;
        line-height: 27px;
      }
    }
    .button_nft {
      margin-top: -50px;
      margin-bottom: 60px;
    }
    .text_1,
    .text_2 {
      margin: 60px 0;
    }
    .tokens {
      margin: 50px 0;
      max-width: 100%;
      &_title {
        font-size: 24px;
        line-height: 27px;
        margin-bottom: 20px;
      }
      &_text {
        & li {
          font-size: 22px;
          line-height: 26px;
          padding-left: 20px;
          margin-bottom: 2px;
          &::before {
            content: "";
            width: 15px;
            height: 15px;
            top: 6px;
          }
        }
      }
    }
  }
}
@media (max-width: 770px) {
  .interior {
    &_image {
      margin-bottom: 33px;
    }
    .about {
      flex-direction: column;

      &_title {
        width: 100%;
        padding-bottom: 20px;
        font-size: 38px;
        line-height: 39px;
        margin-bottom: 40px;
      }
      &_content {
        width: 100%;
      }
    }
    .button_nft {
      max-width: 100%;
      margin-top: 20px;
      margin-bottom: 60px;
      & span {
        text-align: center;
        width: 100%;
      }
    }
    .text_1,
    .text_2 {
      margin: 40px 0;
      font-size: 20px;
      line-height: 30px;
    }
    .tokens {
      margin: 40px 0;
      &_title {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 15px;
      }
      &_text {
        & li {
          font-size: 18px;
          line-height: 24px;
          padding-left: 20px;
          margin-bottom: 2px;
          &::before {
            content: "";
            width: 12px;
            height: 12px;
            top: 7px;
          }
        }
      }
    }
  }
}
