@import "styles/variables.scss";

.image {
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: 58px;
  width: 100%;
  height: 372px;
  background-size: cover;
  background-position: center;
}

@media (max-width: 770px) {
  .image {
    margin-bottom: 40px;
    height: 221px;
  }
}
