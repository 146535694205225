@import "styles/variables.scss";

.card {
  perspective: 1000;
  transform-style: preserve-3d;
  display: inline-block;
  background-color: transparent;
  width: 439px;
  height: 443px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover,
  &:active,
  &:focus {
    .back {
      transform: rotateY(0deg);
    }
    .front {
      transform: rotateY(180deg);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  }

  .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .front,
  .back {
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: absolute;
    top: 0;
    left: 0;
  }

  .front {
    z-index: 2;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  .back {
    padding: 12px 14px;
    background: $secondary-blue;
    color: $text-light;
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);

    .link_wrapper {
      padding: 12px 14px 22px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
    }

    .title {
      font-family: "IBM Plex Mono";
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 62px;
      text-transform: uppercase;
      will-change: transform;
      text-decoration: underline;
    }
    .content {
      & li {
        text-transform: uppercase;
        font-family: "IBM Plex Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: #ffffff;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

//------MEDIA------

@media (max-width: 1400px) {
  .card {
    width: 255px;
    height: 258px;
    .back {
      .title {
        font-size: 21px;
        line-height: 30px;
      }
      .content {
        & li {
          font-size: 15px;
          line-height: 13px;
        }
      }
    }
  }
}

@media (max-width: 770px) {
  .card {
    width: 172px;
    height: 175px;
    .back {
      .title {
        font-size: 13px;
        line-height: 15px;
      }
      .content {
        & li {
          font-size: 9px;
          line-height: 10px;
        }
      }
    }
  }
}
