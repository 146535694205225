@import "styles/variables.scss";
.notfound {
  padding-top: 50px;
  text-align: center;
  .text {
    @include ibm;
    font-size: 68px;
    line-height: 79px;
    text-transform: uppercase;
    color: $projects_text;
  }
}

@media (max-width: 770px) {
  .notfound {
    padding-top: 20px;
    .text {
      font-size: 38px;
      line-height: 60px;
    }
  }
}
