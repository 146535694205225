@import "styles/variables.scss";
img {
  object-fit: cover;
  max-width: 100%;
}
.merch {
  .main {
    margin-bottom: 71px;
    padding: 38px;
    width: 100%;
    height: 511px;
    background-image: url("../../assets/merch/mainscreen.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &_title,
    &_label {
      @include ibm;
    }
    &_title {
      font-size: 64px;
      line-height: 79px;
      margin-bottom: 39px;
      text-transform: uppercase;
    }
    &_label {
      display: block;
      max-width: 350px;
      font-size: 33px;
      line-height: 31px;
    }
  }
  .title {
    @include ibm;
    font-size: 69px;
    line-height: 48px;
    margin-bottom: 58px;
  }
  .grid {
    margin-bottom: 110px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 902px;
    .card {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      padding: 10px 7px 32px 7px;
      border: 1px solid transparent;
      transition: border-color 0.3s;
      & img {
        margin-bottom: 31px;
      }

      &:hover {
        transition: border-color 0.3s;
        border-color: $projects_text;

        .card_more {
          display: block;
        }
        .card_size {
          display: block;
        }
      }

      &_colors {
        margin-bottom: 12px;
        &_black,
        &_black_active,
        &_white_active,
        &_white {
          width: 40px;
          height: 40px;
          border-radius: 100px;
          margin-right: 16px;
        }

        &_white,
        &_white_active {
          background-color: #fff;
          border: 2px solid #d1d1d1;
        }
        &_black,
        &_black_active {
          border: 2px solid #ffffff;
          background-color: #000;
        }
        &_black_active,
        &_white_active {
          box-shadow: 0 0 0 1px #fff, 0 0 0 4px $text-blue;
        }
      }

      &_title {
        font-family: "IBM Plex Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        text-transform: uppercase;
        margin-bottom: 6px;
      }
      &_label {
        display: block;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 14px;
      }
      &_size {
        display: none;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 12px;
      }
      &_price {
        @include ibm700;
        font-size: 24px;
        line-height: 31px;
        text-transform: uppercase;
        font-feature-settings: "ss04";
        display: block;
      }
      &_more {
        color: $text-blue;
        @include ibm500;
        font-size: 20px;
        line-height: 26px;
        text-transform: uppercase;
        border: none;
        outline: none;
        background-color: transparent;
        text-decoration: underline;
        display: none;
      }
    }
  }
}

//-------MEDIA-------

@media (max-width: 1400px) {
  .merch {
    .main {
      margin-bottom: 51px;
      height: 300px;
      background-size: cover;
      background-repeat: no-repeat;

      &_title {
        font-size: 39px;
        line-height: 60px;
        margin-bottom: 19px;
      }
      &_label {
        font-size: 22px;
        line-height: 21px;
      }
    }
    .title {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 38px;
    }
    .grid {
      margin-bottom: 70px;
      grid-template-rows: 590px;

      .card {
        padding: 5px 7px 25px 7px;
        & img {
          margin-bottom: 21px;
        }

        &_colors {
          margin-bottom: 7px;
          &_black,
          &_black_active,
          &_white_active,
          &_white {
            width: 30px;
            height: 30px;
            margin-right: 12px;
          }
        }

        &_title {
          font-size: 22px;
          margin-bottom: 4px;
        }
        &_label {
          font-size: 15px;
          line-height: 20px;
        }
        &_size {
          font-size: 15px;
          line-height: 20px;
          margin-bottom: 8px;
        }
        &_price {
          font-size: 18px;
        }
        &_more {
          font-size: 17px;
          line-height: 20px;
        }
      }
    }
  }
}
@media (max-width: 770px) {
  .merch {
    .main {
      margin-bottom: 31px;
      height: 150px;
      padding: 5px;

      &_title {
        font-size: 23px;
        line-height: 30px;
        margin-bottom: 10px;
      }
      &_label {
        font-size: 18px;
        line-height: 21px;
        max-width: 50%;
      }
    }
    .title {
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 25px;
    }
    .grid {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 500px;
      margin-bottom: 50px;
      .card {
        padding: 5px 7px 25px 7px;
        & img {
          margin-bottom: 21px;
        }

        &_colors {
          margin-bottom: 7px;

          &_black_active,
          &_white_active {
            box-shadow: 0 0 0 1px #fff, 0 0 0 4px $text-blue;
          }
        }

        &_size {
          display: block;
        }
      }
    }
  }
}
