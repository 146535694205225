@import "styles/variables.scss";

.wr {
  padding: 40px 0 305px;
  @include laptop {
    padding: 30px 0 270px;
  }
  @include mobile {
    padding: 30px 0 70px;
  }
  .tabs {
    border-radius: 20px;
    background-color: $text-blue;
    min-height: auto;
    padding: 8px 30px;
    position: relative;
    @include laptop {
      padding: 5px 10px;
    }
    @include mobile {
      background-color: #fff;
      border-radius: 0px;
    }
    & > div {
      & > span {
        //indicator
        background-color: #fff;
        @include mobile {
          display: none;
        }
      }
      & > div {
        //flex-container
        gap: 49px;
        @include laptop {
          gap: 15px;
          justify-content: space-between;
        }
        @include mobile {
          gap: 5px;
        }
      }
    }
    .tab {
      padding: 5px;
      border-radius: 10px;
      min-height: auto;
      max-width: 100%;
      color: #fff;
      @include ibm;
      font-size: 20px;
      line-height: normal;
      text-transform: uppercase;
      transition: opacity 0.3s;
      @include laptop {
        font-size: 17px;
      }
      @include mobile {
        background-color: $text-blue;
        border-radius: 3px;
      }
      &:hover {
        opacity: 0.8;
      }
      &:focus {
        opacity: 1;
        @include mobile {
          opacity: 0.8;
        }
      }
    }
  }
}

.tab_content {
  padding-top: 56px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 130px 50px;
  @include laptop {
    row-gap: 50px;
  }
  @include mobile {
    row-gap: 70px;
    grid-template-columns: 1fr;
    padding-top: 20px;
  }
  &__web {
    & > li {
      &:first-child {
        & > div > p {
          max-width: 449px;
        }
      }
      &:nth-child(2) {
        transform: translateY(20%);
        @include mobile {
          transform: none;
        }
        & > div > p {
          max-width: 339px;
        }
      }
      &:last-child {
        & > div > p {
          max-width: 530px;
        }
      }
    }
  }
  &__app {
    & > li {
      max-width: 488px;
    }
  }
  &__ar {
    & > li {
      &:first-child {
        & > div > p {
          max-width: 332px;
        }
      }
      &:nth-child(2) {
        transform: translateY(20%);
        @include mobile {
          transform: none;
        }
        max-width: 332px;
      }
      &:nth-child(3) {
        & > div > p {
          max-width: 449px;
        }
      }
      //&:last-child {
      //  transform: translateY(40%);
      //  @include mobile {
      //    transform: none;
      //  }
      //  & > div > p {
      //    max-width: 549px;
      //  }
      //}
    }
  }
  &__game {
    grid-template-columns: repeat(3, 1fr);
    gap: 182px 0;
    @include laptop {
      grid-template-columns: 1fr 1fr;
      gap: 90px 35px;
    }
    @include mobile {
      row-gap: 70px;
      grid-template-columns: 1fr;
      padding-top: 20px;
    }
    & > li {
      &:first-child {
        & > div > p {
          max-width: 332px;
        }
      }
      &:nth-child(2) {
        max-width: 332px;
        transform: translateY(23%);
        @include mobile {
          transform: none;
        }
      }
      &:nth-child(3) {
        max-width: 418px;
        @include laptop {
          grid-column: 2;
          transform: translateY(30%);
        }
        @include mobile {
          grid-column: initial;
          transform: none;
        }
      }
      &:nth-child(4) {
        transform: translateY(15%);
        @include laptop {
          transform: none;
          grid-row: 2;
        }
        @include mobile {
          grid-row: initial;
        }

        & > div > p {
          max-width: 332px;
        }
      }
      &:last-child {
        grid-column: 3;
        max-width: 533px;
        transform: translateX(-20%);
        @include laptop {
          grid-column: 1;
          transform: none;
        }
      }
    }
  }
  &__graph {
    & > li {
      &:first-child {
        transform: translateX(20%);
        @include laptop {
          transform: none;
        }

        & > div > p {
          max-width: 449px;
        }
      }
      &:nth-child(2) {
        transform: translateY(20%);
        @include mobile {
          transform: none;
        }
        & > div > p {
          max-width: 439px;
        }
      }
    }
  }
}

.tab_card > div {
  & > img {
    border-radius: 24px;
    margin-bottom: 16px;
    @include mobile {
      margin-bottom: 5px;
    }
  }
  & > h2 {
    color: $projects_text;
    @include ibm;
    font-size: 44px;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 14px;
    @include mobile {
      font-size: 27px;
      margin-bottom: 5px;
    }
  }
  & > p {
    color: rgba(24, 24, 24, 0.8);
    @include mulish;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 16px;
    @include mobile {
      font-size: 13px;
      margin-bottom: 5px;
    }
  }
  & > span {
    display: block;
    color: $projects_text;
    @include ibm;
    font-size: 24px;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 16px;
    @include mobile {
      font-size: 20px;
      margin-bottom: 7px;
    }
  }
  & button {
    color: $text-blue;
    @include ibm600;
    font-size: 24px;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 18px;
    @include hoveredText;
    @include mobile {
      font-size: 19px;
      gap: 10px;
    }
    & > svg {
      transition: transform 0.3s;
    }
    &:hover {
      background-size: 100% 1.5px;
      & > svg {
        transform: translateX(5px);
      }
    }
  }
}
