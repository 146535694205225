@import "styles/variables.scss";

.card {
  height: 100%;
  position: relative;
  &:hover {
    .front {
      transition: opacity 0.5s ease, transform 0.5s ease;
      transform: scale(0);
      opacity: 0;
    }
    .back {
      transition: opacity 0.5s ease, transform 0.5s ease;
      transform: scale(1.05);
      z-index: 10;
      opacity: 1;
    }
  }

  .front {
    opacity: 1;
    display: block;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .back {
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 12px 14px;
    background: $secondary-blue;
    color: $text-light;

    .link_wrapper {
      padding: 12px 14px 22px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
    }

    .title {
      font-family: "IBM Plex Mono";
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 62px;
      text-transform: uppercase;
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
      }
    }
    .content {
      & li {
        text-transform: uppercase;
        font-family: "IBM Plex Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: #ffffff;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
}
//------MEDIA------

@media (max-width: 1400px) {
  .card {
    .back {
      .title {
        font-size: 28px;
        line-height: 40px;
      }
      .content {
        & li {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }
}

@media (max-width: 770px) {
  .card {
    .back {
      .title {
        font-size: 16px;
        line-height: 20px;
      }
      .content {
        & li {
          font-size: 9px;
          line-height: 13px;
        }
      }
    }
  }
}
